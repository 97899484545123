import Vue from 'vue';
import WOW from 'wowjs';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import App from './App.vue';
import router from './router';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useLanguageStore } from '@/stores/language';

Vue.config.productionTip = false;
const pinia = createPinia();
Vue.use(PiniaVuePlugin);

// Agregar vue-i18n versión 8
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

// Definir los mensajes en diferentes idiomas
const messages = {
  es: {
    welcomeMessage: '¡Bienvenido al sitio web!',
  },
  en: {
    welcomeMessage: 'Welcome to the website!',
  }
};

// Crear la instancia de i18n
const i18n = new VueI18n({
  locale: 'es', // Establecer español como idioma por defecto
  fallbackLocale: 'en', // Idioma alternativo
  messages, // Mensajes de traducción
}); 

// Inicializar el idioma basado en localStorage o navegador
const languageStore = useLanguageStore(pinia);
languageStore.initializeLanguage(); // Llama a la inicialización del idioma
i18n.locale = languageStore.language; // Establece el idioma en i18n basado en el valor de `languageStore`



new Vue({
  pinia,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
