<template>
  <div id="nqa-widget-container" style="position: fixed; bottom: 20px; right: 20px; z-index: 1000;">
    <!-- El widget se cargará aquí dinámicamente -->
  </div>
</template>

<script>
export default {
  name: 'NQAWidget',
  data() {
    return {
      botId: null,
      widgetLoaded: false
    };
  },
  methods: {
    loadWidget() {
      if (this.botId && !this.widgetLoaded) {
        window.nqaSettings = {
          bot_id: this.botId,
          host: process.env.VUE_APP_HOST_CORS
        };
        const script = document.createElement('script');
        script.src = `${window.nqaSettings.host}/static/nqa-widget.js`;
        script.async = true;
        script.onload = () => {
          if (window.NQAWidget && typeof window.NQAWidget.init === 'function') {
            window.NQAWidget.init(window.nqaSettings);
            this.widgetLoaded = true;
          } else {
            console.error('NQAWidget.init no está disponible');
          }
        };
        document.head.appendChild(script);
      }
    },
    async fetchBotId() {
      try {
        const response = await fetch(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/default_parameter_cookie`, {
          withCredentials: false,
        });
        const data = await response.json();
        const sharebot_chat_url = data.message.sharebot_chat_url;
        if (sharebot_chat_url) {
          const idMatch = sharebot_chat_url.match(/[?&]id=([^&]+)/);
          this.botId = idMatch ? idMatch[1] : null;
          this.loadWidget();
        }
      } catch (error) {
        console.error('Error al obtener el ID del bot:', error);
      }
    },
    initializeWidget() {
      this.fetchBotId();
    }
  },
  mounted() {
    this.initializeWidget();
  }
};
</script>