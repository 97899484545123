<template>
	<div>
		<div class="my-app home-two-dark">
			<Header2 class="header--dark" />
			<MobileMenu />
			<div class="mobile-menu__backdrop"></div>
			<Banner />
			<CTA />
			<Testimonials />
			<Generate />
			<Tour />
			<About />
			<Publish />
			<hr id="horizon-break">			
			<Pricing />
			<FAQ />			
			<!--<Tools />-->
			<Footer2 />
		</div>
		<BackToTop />
	</div>
</template>

<script>
	import Header2 from '../components/layout/Header2.vue'
	import MobileMenu from '../components/layout/MobileMenu.vue'
	import Banner from '../components/page_components/home-2/Banner.vue'
	import CTA from '../components/page_components/home-2/CTA.vue'
	import Testimonials from '../components/page_components/home-2/Testimonials.vue'
	import Generate from '../components/page_components/home-2/Generate.vue'
	import Tour from '../components/page_components/home-2/Tour.vue'
	import About from '../components/page_components/home-2/About.vue'
	import Publish from '../components/page_components/home-2/Publish.vue'	
	import Pricing from '../components/page_components/home-2/Pricing.vue'
	import FAQ from '../components/page_components/home-2/FAQ.vue'	
	//import Tools from '../components/page_components/home-2/Tools.vue'
	import Footer2 from '../components/layout/Footer2.vue'
	import BackToTop from '../components/common/BackToTop.vue'
	export default {
		name: "Home",
		components: {
			Header2,
			MobileMenu,
			Banner,
			CTA,
			Testimonials,
			Generate,
			Tour,
			About,
			Publish,			
			Pricing,
			FAQ,			
			//Tools,
			Footer2,
			BackToTop
		},
	};
</script>

<style>
	.home-two-dark .anime--dark {
		display: none;
	}

	.home-two-light .anime--light {
		display: none;
	}
</style>