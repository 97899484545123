<template>
    <section id="FAQ" class="section contact-main">
        <div class="container">
            <div class="row items-gap align-items-center">
                <div class="col-12 col-lg-6 col-xxl-5">
                    <div class="contact-main__content wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <h2 class="h3">
                            {{ labelsState['need_help'] || '¿Need help?' }}
                            <span>{{ labelsState['contact_us'] || 'Contact Us.' }}</span>
                        </h2>
                        <p>
                            {{ labelsState['contact_description'] || 'Do not hesitate, talk to one of our advisors, we will help you with any concerns whenever you need it.' }}
                        </p>
                        <div class="arrow">
                            <img :src="require('@/assets/images/arrow-contact.png')" alt="Image">
                        </div>
                        <div class="cta-contact">
                            <a href="https://wa.me/3027506799" target="_blank" rel="noopener noreferrer">
                                WhatsApp
                                <i class="fa-solid fa-angle-right"></i>
                            </a>
                            <span>{{ labelsState['or'] || 'O' }}</span>
                            <a href="mailto:contacto@nqartificial.com">
                                {{ labelsState['email'] || 'Correo electronico' }}
                                <i class="fa-solid fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </div>                
                <div class="col-12 col-lg-6 col-xxl-6 offset-xxl-1">
                    <div class="contact-main__form wow fadeInUp" data-wow-duration="600ms"
                        data-wow-delay="300ms">                        
                        <iframe v-if="sharebotChatUrl" :src="sharebotChatUrl" frameborder="0" width="100%" height="500px"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';

export default {
    name: "Contact",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();
        const sharebotChatUrl = ref('');
        const fetchDefaultValues = async () => {
            try {
                const response = await axios.get(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/default_parameter_cookie`, {
                    withCredentials: false,
                });

                const response_data = response.data;
                sharebotChatUrl.value = response_data['message']['sharebot_chat_url'];
            } catch (error) {
                console.error('Error fetching default values:', error);
            }
        };
        onMounted(() => {
            fetchDefaultValues();
        });

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);

        return {
            labelsState,
            languageCodes,
            sharebotChatUrl,
            changeLanguage: (languageCode) => labelsStore.fetchAndSetLabels(languageCode),
        };
    },
};
</script>

<style scoped>
.chat-container {
    margin-top: 20px;
}

.chat-container iframe {
    border: none;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .chat-container iframe {
        height: 400px;
    }
}
</style>