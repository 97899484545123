<template>
    <section class="section publish">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
                    <div class="publish__inner">
                        <div class="row justify-content-center">
                            <div class="col-15">
                                <div class="section__header wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="300ms">
                                    <h2 class="h2">{{ labelsState['why_choose_nqa_bot'] || '¿Why choose NQA Chat-Bot??' }}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row items-gap">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['personalized_attention'] || 'Personalized Attention' }}</h5>
                                        <p>{{ labelsState['personalized_attention_description'] || 'Quick and complete responses to your customers from any device, regardless of the day or time' }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="300ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['protected_information'] || 'Protected Information' }}</h5>
                                        <p>{{ labelsState['protected_information_description'] || 'At NQA we make sure to keep your information and that of your clients 100% confidential.' }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="600ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['customer_service'] || 'Customer service 24/7' }}</h5>
                                        <p>{{ labelsState['customer_service_description'] || 'With our NQA ChatBot, you can connect with your customers 24/7, respond automatically, and personalize the experience to your brand. Best of all, no programming required!' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section__content-cta text-center wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                            <a :href="registerUrl" class="btn btn--senary" target="_blank">
                                {{ labelsState['get_free_demo'] || 'Get a totally free demo' }}
                            </a>
                            <img :src="require('@/assets/images/frame-three.png')" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </section>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: "Publish",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);
        const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;

        return {
            labelsState,
            languageCodes,
            changeLanguage,
            registerUrl,
        };
    },
};
</script>
