import { defineStore } from 'pinia';

export const useLanguageStore = defineStore('language', {
  state: () => ({
    // Cargar idioma desde localStorage o por defecto 'es' (español)
    currentLanguage: localStorage.getItem('currentLanguage') || 'es',
    codes: JSON.parse(localStorage.getItem('languageCodes') || '["es", "en"]'), // Idiomas disponibles
  }),
  actions: {
    initializeLanguage() {
      // Si hay un idioma guardado en localStorage, úsalo
      const storedLanguage = localStorage.getItem('currentLanguage');
      if (storedLanguage) {
        this.currentLanguage = storedLanguage;
      } else {
        // Si no, utiliza el idioma del navegador, pero por defecto 'es'
        const browserLanguage = navigator.language.split('-')[0]; // Solo el código principal (es, en)
        if (this.codes.includes(browserLanguage)) {
          this.currentLanguage = browserLanguage;
        } else {
          this.currentLanguage = 'es'; // Español como predeterminado
        }
        localStorage.setItem('currentLanguage', this.currentLanguage); // Guardar en localStorage
      }
    },
    setLanguage(languageCode) {
      this.currentLanguage = languageCode;
      localStorage.setItem('currentLanguage', languageCode); // Guardar el idioma elegido
    },
    setCodes(newCodes) {
      this.codes = newCodes;
      localStorage.setItem('languageCodes', JSON.stringify(newCodes));
    },
  },
  getters: {
    language: (state) => state.currentLanguage, // Obtener el idioma actual
  }
});

