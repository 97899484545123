<template>
  <div>
    <div class="mobile-menu d-block d-xl-none">
      <nav class="mobile-menu__wrapper">
        <div class="mobile-menu__header">
          <div class="nav__logo">
            <router-link
              to="/"
              aria-label="home page"
              title="logo"
              @click.native="closeMenu"
            >
              <img
                :src="require('@/assets/images/LOGO VERSIÓN FINAL NQA.png')"
                alt="Image"
              />
            </router-link>
          </div>
          <button
            aria-label="close mobile menu"
            class="close-mobile-menu"
            @click.native="closeMenu"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="mobile-menu__list">
          <router-link
            to="/"
            class="nav__menu-link hide-nav mobile-menu__item"
            @click.native="closeMenu"
          >
            {{ labelsState["start"] || "Start" }}
          </router-link>

          <router-link
            :to="{ path: '/', hash: '#pricing' }"
            class="nav__menu-link hide-nav mobile-menu__item"
            @click.native="closeMenu"
          >
            {{ labelsState["plans"] || "Plans" }}
          </router-link>

          <router-link
            :to="{ path: '/', hash: '#about' }"
            class="nav__menu-link hide-nav mobile-menu__item"
            @click.native="closeMenu"
          >
            {{ labelsState["about_us"] || "About Us" }}
          </router-link>

          <router-link
            :to="{ path: '/', hash: '#FAQ' }"
            class="nav__menu-link hide-nav mobile-menu__item"
            @click.native="closeMenu"
          >
            {{ labelsState["contact_us"] || "Contact Us" }}
          </router-link>

          <router-link
            to="/Appointment"
            class="nav__menu-link hide-nav mobile-menu__item"
            @click.native="closeMenu"
          >
            {{ labelsState["appointment"] || "Appointment" }}
          </router-link>

          <router-link
            :to="{ path: '/', hash: '#CTA' }"
            class="nav__menu-link hide-nav mobile-menu__item"
            @click.native="closeMenu"
          >
            {{ labelsState["events"] || "Events" }}
          </router-link>

          <a
            href="https://www.youtube.com/@NeuroQuantumArtificial/playlists"
            target="_blank"
            class="nav__menu-link hide-nav mobile-menu__item"
            @click.native="closeMenu"
          >
            {{ labelsState["tutorials"] || "Tutorials" }}
          </a>

          <a
            :href="loginUrl"
            target="_blank"
            class="btn btn--septenary mobile-menu__item"
          >
            {{ labelsState["login"] || "Login" }}
          </a>

          <a
            :href="registerUrl"
            target="_blank"
            class="btn btn--septenary mobile-menu__item"
          >
            {{ labelsState["register"] || "Register" }}
          </a>
        </div>
        <div class="mobile-menu__social social">
          <a
            href="https://www.facebook.com/people/Neuro-Quantum-Artificial/61558761272414/"
            aria-label="social media"
          >
            <i class="fa-brands fa-facebook-f"></i>
          </a>
          <a href="https://x.com/NQArtificial" aria-label="social media">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/nqartificial/mycompany/"
            aria-label="social media"
          >
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
          <a
            href="https://www.instagram.com/nqartificial/?igsh=bGs1bzd2dTQ1ZHA1"
            aria-label="social media"
          >
            <i class="fa-brands fa-instagram"></i>
          </a>
        </div>
      </nav>
    </div>
    <div class="mobile-menu__backdrop" @click.native="closeMenu"></div>
  </div>
</template>

<script>
import { useLabelsStore } from "@/stores/labels";
import { useLanguageStore } from "@/stores/language";
import { computed } from "vue";

export default {
  name: "MobileMenu",
  setup() {
    const labelsStore = useLabelsStore();
    const languageStore = useLanguageStore();

    const changeLanguage = (languageCode) => {
      labelsStore.fetchAndSetLabels(languageCode);
    };

    const labelsState = computed(() => labelsStore.labels);
    const languageCodes = computed(() => languageStore.codes);
    const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;
    const loginUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-login`;

    return {
      labelsState,
      languageCodes,
      changeLanguage,
      registerUrl,
      loginUrl,
    };
  },

  methods: {
    closeMenu() {
      const mobileMenu = document.querySelector(".mobile-menu");
      const backdrop = document.querySelector(".mobile-menu__backdrop");
      if (mobileMenu) {
        mobileMenu.classList.remove("show-menu");
      }
      if (backdrop) {
        backdrop.classList.remove("mobile-menu__backdrop-active");
      }
      document.body.classList.remove("body-active");
    },
  },

  mounted() {
    document.querySelector(".nav__bar").addEventListener("click", function () {
      document
        .querySelector(".mobile-menu__backdrop")
        .classList.add("mobile-menu__backdrop-active");
      document.querySelector(".mobile-menu").classList.add("show-menu");
      document.body.classList.add("body-active");
    });
    document
      .querySelectorAll(".close-mobile-menu, .mobile-menu__backdrop")
      .forEach((element) => {
        element.addEventListener("click", this.closeMenu);
      });
  },
};
</script>
