<template>
    <div>
        <div class="my-app home-two-dark">
			<Header2 class="header--dark" />
			<MobileMenu />
            <section class="section team-main pb-0">
                <div class="container">                    
                </div>
                <div class="row items-gap align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="team-main-single wow fadeInLeft" data-wow-duration="600ms" data-wow-delay="300ms">
                        <!--<img :src="require('@/assets/images/2. Confuse Bot.png')" alt="Image">-->
                    
                        <h1 class="h1">{{ labelsState['policies'] || 'policies' }}</h1>

                        <h1 class="h2">{{ labelsState['introduction'] || 'Introduction' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['introduction_text_two'] || 'These Terms of Use (hereinafter, the "Policies") establish the guidelines and rules for interaction with the NQA Chatbot service (hereinafter, the "Chatbot") provided by Neuro Quantum Artificial (hereinafter, the "Company"). These Policies are complementary to the Terms and Conditions of Use of the Chatbot. By using the Chatbot, the user (hereinafter, the "User") agrees to these Policies.' }}
                            </p>                                                       
                        </div>

                        <h1 class="h2">{{ labelsState['data_collection_use'] || 'Data Collection and Use' }}</h1>
                        <h1 class="h3">{{ labelsState['collected_data'] || 'Collected Data' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['collected_data_text'] || 'The Chatbot may collect various types of personal and non-personal data from the User, including: Contact Information: name, email, phone number, company name, etc. Interaction Information: messages sent to the Chatbot, responses, feedback. Technical Information: IP address, device type, browser used.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['purpose_data_collection'] || 'Purpose of Collection' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['purpose_data_collection_text'] || 'The data collected is used to: Provide and improve the services offered through the Chatbot. Personalize the User experience. Respond to User inquiries and requests. Conduct market analysis and studies. Comply with legal and regulatory requirements.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['user_consent'] || 'User Consent' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['user_consent_text'] || 'The User gives their consent to the collection and use of their personal data by interacting with the Chatbot. The User may withdraw their consent at any time by contacting the Company through the provided channels.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['privacy_data_protection'] || 'Privacy and Data Protection' }}</h1>
                        <h1 class="h3">{{ labelsState['data_processing'] || 'Personal Data Processing' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['data_processing_text'] || 'The Company is committed to processing the User is personal data in accordance with applicable data protection laws, including but not limited to the General Data Protection Regulation (GDPR) and Colombia is Personal Data Protection Law, Law 1581 of 2012.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['security_measures'] || 'Security Measures' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['security_measures_text'] || 'The Company implements appropriate technical and organizational security measures to protect the User is personal data against unauthorized access, disclosure, alteration, or destruction.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['user_rights'] || 'User Rights' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['user_rights_text'] || 'The User has the right to: Access their personal data. Rectify incorrect or incomplete data. Request the deletion of their data. Object to the processing of their data. Request the portability of their data. To exercise these rights, the User may contact the Company through the provided channels.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['acceptable_use_chatbot'] || 'Acceptable Use of the Chatbot' }}</h1>
                        <h1 class="h3">{{ labelsState['user_conduct'] || 'User Conduct' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['user_conduct_text'] || 'The User agrees to use the Chatbot ethically and legally, adhering to the following rules: Do not use the Chatbot for illegal activities. Do not send offensive, defamatory, or inappropriate content. Do not interfere with the operation of the Chatbot. Do not attempt to access restricted areas of the Chatbot.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['suspension_termination'] || 'Suspension and Termination' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['suspension_termination_text'] || 'The Company reserves the right to suspend or terminate the User is access to the Chatbot if it believes the User has violated these Policies or any applicable law.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['intellectual_property'] || 'Intellectual Property' }}</h1>
                        <h1 class="h3">{{ labelsState['property_rights'] || 'Property Rights' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['property_rights_text'] || 'All intellectual property rights related to the Chatbot, including but not limited to software, text, graphics, and content, are the exclusive property of the Company or its licensors.' }}
                            </p>
                        </div>

                        <h1 class="h3">{{ labelsState['usage_license'] || 'Usage License' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['usage_license_text'] || 'The Company grants the User a limited, non-exclusive, non-transferable, and revocable license to use the Chatbot in accordance with these Policies.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['policy_modifications'] || 'Modifications to the Policies' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['policy_modifications_text2'] || 'The Company may modify these Policies at any time. Modifications will take effect upon publication on the platforms where the Chatbot is available. The continued use of the Chatbot by the User after the publication of modifications will constitute acceptance of such modifications.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['contact_complaints'] || 'Contact and Complaints' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['contact_complaints_text'] || 'For any inquiries, complaints, or requests related to the use of the Chatbot, the User may contact the Company through the following means: Email: contacto@nqartificial.com Phone: +57 3027506799.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['policy_acceptance'] || 'Policy Acceptance' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['policy_acceptance_text'] || 'The use of the Chatbot implies full and unconditional acceptance of these Policies by the User. If the User does not agree with any of the policies established herein, they must refrain from using the Chatbot.' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer2 />
        </div>
        <BackToTop />
    </div>
</template>

<script>
    import Header2 from '../components/layout/Header2.vue'
	import MobileMenu from '../components/layout/MobileMenu.vue'
    import Footer2 from '../components/layout/Footer2.vue'
    import BackToTop from '../components/common/BackToTop.vue'
    import { useLabelsStore } from '@/stores/labels';
    import { useLanguageStore } from '@/stores/language';
    import { computed, onMounted } from 'vue';
    import axios from 'axios';

    export default {
        name: "UseCase",
        components: {
            Header2,
			MobileMenu,
            Footer2,
            BackToTop
        },
        name: "UseCase",
            setup() {
                const labelsStore = useLabelsStore();
                const languageStore = useLanguageStore();

                const changeLanguage = (languageCode) => {
                    labelsStore.fetchAndSetLabels(languageCode);
                };

                const labelsState = computed(() => labelsStore.labels);
                const languageCodes = computed(() => languageStore.codes);

                return {
                    labelsState,
                    languageCodes,
                    changeLanguage,
                };
            },
    };
</script>