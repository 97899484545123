<template>
    <div>
        <div class="my-app home-two-dark">
			<Header2 class="header--dark" />
			<MobileMenu />
            <section class="section team-main pb-0">
                <div class="container">                    
                </div>
                <div class="row items-gap align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="team-main-single wow fadeInLeft" data-wow-duration="600ms" data-wow-delay="300ms">
                        <!--<img :src="require('@/assets/images/2. Confuse Bot.png')" alt="Image">-->
                    
                        <h1 class="h1">{{ labelsState['data_processing_nqa'] || 'NQA Data Processing' }}</h1>                        

                        <h1 class="h2">{{ labelsState['introduction'] || 'Introduction' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['introduction_text'] || "At Neuro Quantum Artificial, hereinafter the Company, we respect and protect the privacy of users, hereinafter the User, who interact with our chatbot service NQA, hereinafter the Chatbot. This Personal Data Processing Policy, hereinafter the Policy, describes how we collect, use, protect and share the User is personal information. By using the Chatbot, the User agrees to the terms of this Policy."}}
                            </p>                                                       
                        </div>

                        <h1 class="h2">{{ labelsState['data_controller'] || 'Data Controller' }}</h1> 
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['data_controller_text'] || 'The Company is responsible for the processing of personal data collected through the Chatbot. For anyquery or request related to this Policy, the User may contact the Company through the following means:Email: contacto@nqartificial.com Telephone: (+57)3027506799'}}  </p>                                                       
                        </div>
                        
                        <h1 class="h2">{{ labelsState['personal_data_collected'] || 'Personal Data Collected' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['personal_data_collected_text'] || 'We may collect the following personal data from the User: Contact Information: name, email address, phone number, company name. Interaction Data: messages and responses provided to the Chatbot. Technical Data: IP address, device type, operating system, web browser.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['purpose_data_processing'] || 'Purpose of Data Processing' }}</h1>
                        <h1 class="h3">{{ labelsState['user_conduct'] || '4.1. User Conduct' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['purpose_data_processing_text'] || 'The personal information collected through the Chatbot is used for the following purposes: Service Provision: to provide and improve the services offered through the Chatbot. Personalization: to personalize the User experience and offer relevant content. Customer Support: to respond to User inquiries and requests. Analytics and Improvement: to conduct statistical analysis and market studies to improve our services. Legal Compliance: to comply with legal obligations and applicable regulations.' }}
                            </p>
                        </div>  

                        <h1 class="h2">{{ labelsState['legal_basis_data_processing'] || 'Legal Basis for Data Processing' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['legal_basis_data_processing_text'] || 'The processing of the User is personal data is based on the following legal grounds: Consent: the User consents to the processing of their personal data by interacting with the Chatbot. Contract Execution: the processing is necessary for the execution of a contract in which the User is a party. Legitimate Interests: the processing is necessary for the Company is legitimate interests, such as improving our services and ensuring the security of the Chatbot.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['user_rights'] || 'User Rights' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['user_rights_text'] || 'The User has the following rights regarding their personal data: Access: the right to access their personal data that we process. Rectification: the right to request correction of inaccurate or incomplete personal data. Deletion: the right to request the deletion of their personal data. Objection: the right to object to the processing of their personal data. Portability: the right to request the transfer of their personal data to another entity. To exercise these rights, the User may contact the Company through the means indicated in section 2 of this Policy.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['data_security'] || 'Data Security' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['data_security_text'] || 'The Company implements appropriate technical and organizational security measures to protect the User is personal data from unauthorized access, alteration, disclosure, or destruction.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['data_retention'] || 'Data Retention' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['data_retention_text'] || 'The User is personal data will be retained for as long as necessary to fulfill the purposes for which it was collected, as well as to comply with legal obligations, resolve disputes, and enforce our agreements.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['data_transfer_third_parties'] || 'Data Transfer to Third Parties' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['data_transfer_third_parties_text'] || 'The Company will not share the User is personal data with third parties, except in the following circumstances: Service Providers: we may share personal data with third parties who provide services to us to assist in the operation of our Chatbot and the provision of our services. Legal Obligations: we may disclose the User is personal data when required by law or in response to legal requests from competent authorities.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['use_cookies'] || 'Use of Cookies and Similar Technologies' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['use_cookies_text'] || 'The Chatbot may use cookies and similar technologies to collect technical information about the User. This information is used to improve the functionality of the Chatbot and personalize the User experience. The User can control the use of cookies through their browser settings.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['policy_modifications'] || 'Modifications to the Policy' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['policy_modifications_text'] || 'The Company reserves the right to modify this Policy at any time. Modifications will take effect upon publication on the platforms where the Chatbot is available. The continued use of the Chatbot by the User after the publication of the modifications will constitute acceptance of such modifications.' }}
                            </p>
                        </div>

                        <h1 class="h2">{{ labelsState['policy_acceptance'] || 'Policy Acceptance' }}</h1>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">
                                {{ labelsState['policy_acceptance_text'] || 'The use of the Chatbot implies full and unconditional acceptance of this Policy by the User. If the User does not agree with any of the terms set forth herein, they should refrain from using the Chatbot.' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer2 />
        </div>
        <BackToTop />
    </div>
</template>

<script>
    import Header2 from '../components/layout/Header2.vue'
	import MobileMenu from '../components/layout/MobileMenu.vue'
    import Footer2 from '../components/layout/Footer2.vue'
    import BackToTop from '../components/common/BackToTop.vue'
    import { useLabelsStore } from '@/stores/labels';
    import { useLanguageStore } from '@/stores/language';
    import { computed, onMounted } from 'vue';
    import axios from 'axios';

    export default {
        name: "UseCase",
        components: {
            Header2,
			MobileMenu,
            Footer2,
            BackToTop
        },
        name: "UseCase",
            setup() {
                const labelsStore = useLabelsStore();
                const languageStore = useLanguageStore();

                const changeLanguage = (languageCode) => {
                    labelsStore.fetchAndSetLabels(languageCode);
                };

                const labelsState = computed(() => labelsStore.labels);
                const languageCodes = computed(() => languageStore.codes);

                return {
                    labelsState,
                    languageCodes,
                    changeLanguage,
                };
            },
    };
</script>