<template>
	<div>
		<div class="my-app home-two-dark">
			<Header2 class="header--dark" />
			<MobileMenu />
			<div class="mobile-menu__backdrop"></div>
			<PageHeader page-title="Sobre Neuro" />
			<Introduction />
			<Footer2 />
		</div>
		<BackToTop />
	</div>
</template>

<script>
	import Header2 from '../components/layout/Header2.vue'
	import MobileMenu from '../components/layout/MobileMenu.vue'	
	import Introduction from '../components/page_components/about/Introduction.vue'
	import Footer2 from '../components/layout/Footer2.vue'
	import BackToTop from '../components/common/BackToTop.vue'
	export default {
		name: "Home",
		components: {
			Header2,
			MobileMenu,
			Introduction,
			Footer2,
			BackToTop
		},
	};
</script>
<style>
	.home-two-dark .anime--dark {
		display: none;
	}
</style>	
