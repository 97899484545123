<template>
    <div>
        <div class="my-app home-two-dark">
            <Header2 class="header--dark" />
			<MobileMenu />
			<div class="mobile-menu__backdrop"></div>
            <PageHeader page-title="Contact Us" />
            <section class="section contact-main">
                <div class="container">
                    <div class="row items-gap justify-content-center align-items-center text-center">
                        <div class="col-12 col-lg-6 col-xxl-5">
                            <div class="contact-main__content wow fadeInUp" data-wow-duration="600ms"
                                data-wow-delay="300ms">
                                <h2 class="h3">
                                    {{ labelsState['need_help'] || '¿Need help?' }}
                                    <span>{{ labelsState['contact_us'] || 'Contact Us.' }}</span>
                                </h2>
                                <p>
                                    {{ labelsState['contact_description'] || 'Do not hesitate, talk to one of our advisors, we will help you with any concerns whenever you need it.' }}
                                </p>
                                <div class="arrow">
                                    <img :src="require('@/assets/images/arrow-contact.png')" alt="Image">
                                </div>
                                <div class="cta-contact">
                                    
                                        <p>{{ labelsState['whatsapp'] || 'Whatsapp' }}: <a  rel="noopener noreferrer">
                                            (+57)3027506799
                                        </a></p>
                                        <p>{{ labelsState['or'] || 'or' }}</p>
                                        <p>{{ labelsState['email'] || 'Email' }} <a>
                                            contacto@nqartificial.com
                                        </a></p>                                    
                                </div>
                            </div>
                        </div>                       
                    </div>
                </div>
            </section>
            <Footer2 />            
        </div>
        <BackToTop />
    </div>
</template>

<script>
    import Header2 from '../components/layout/Header2.vue'
	import MobileMenu from '../components/layout/MobileMenu.vue'
    import Footer2 from '../components/layout/Footer2.vue'
    import BackToTop from '../components/common/BackToTop.vue'
    import { useLabelsStore } from '@/stores/labels';
    import { useLanguageStore } from '@/stores/language';
    import { computed, onMounted } from 'vue';
    import axios from 'axios';

    export default {
        name: "Contact",
        components: {
            Header2,
			MobileMenu,
            Footer2,
            BackToTop,
        },
        name: "Contact",
            setup() {
                const labelsStore = useLabelsStore();
                const languageStore = useLanguageStore();

                const changeLanguage = (languageCode) => {
                    labelsStore.fetchAndSetLabels(languageCode);
                };

                const labelsState = computed(() => labelsStore.labels);
                const languageCodes = computed(() => languageStore.codes);

                return {
                    labelsState,
                    languageCodes,
                    changeLanguage,
                };
            },
    };
</script>

<style scoped>
    .contact-main .subject:focus-visible {
        outline: none;
    }

    .update-cta {
        padding-bottom: 140px !important;
    }

    .cta-contact .help-info {
    font-size: 1.1rem;
    justify-content: center;
    gap: 20px; 
    margin: 0;
    }
    .contact-main__content h2 {
    font-size: 2rem;
    line-height: 1.2;
    }

    .contact-main__content p {
        font-size: 1.2rem;
        line-height: 1.5;
    }
    .cta-contact {
        margin-top: 20px;
    }

    .cta-contact p {
        font-size: 1rem;
    }

    .cta-contact a {
        color: #00CC99;
        text-decoration: none;
        font-weight: bold;
    }

    .cta-contact a:hover {
        text-decoration: underline;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .col-12 {
        width: 100%;
    }
    @media (min-width: 768px) {
        .col-lg-6 {
            width: 50%;
        }
        
        .contact-main__content h2 {
            font-size: 2.5rem;
        }
        
        .contact-main__content p {
            font-size: 1.5rem;
        }
    }
    @media (min-width: 992px) {
        .col-xxl-5 {
            width: 40%;
        }
        
        .contact-main__content h2 {
            font-size: 3rem;
        }
        
        .contact-main__content p {
            font-size: 1.2rem;
        }
        
        .cta-contact p {
            font-size: 1.1rem;
        }
    }

    @media (max-width: 576px) {
        .contact-main {
            padding: 30px 10px;
        }

        .contact-main__content h2 {
            font-size: 1.8rem;
        }

        .contact-main__content p {
            font-size: 1rem;
        }

        .cta-contact p {
            font-size: 0.9rem;
        }

        .cta-contact a {
            font-size: 1rem;
        }
    }
</style>