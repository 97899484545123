<template>
    <section id="about" class="section clone about-main">
        <div class="container">
            <div class="row items-gap align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="about__thumb wow fadeInLeft" data-wow-duration="600ms" data-wow-delay="300ms">
                        <img :src="require('@/assets/images/aboutUs.png')" alt="Image">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="clone__content section__content wow fadeInUp" data-wow-duration="600ms"
                        data-wow-delay="600ms">
                        <h2 class="h2">{{ labelsState['what_is_nqa'] || '¿What is Artificial Neuro Quantum??' }}</h2>
                        <div class="paragraph">
                            <p class="fw-5 text-lg">{{ labelsState['about_description'] || 'NQA is a company dedicated to offering specialized products and services for companies in various sectors, such as e-commerce, retail, banking and education. We stand out for our advanced artificial intelligence solutions, including chat bots, designed to optimize processes, improve decision-making and increase operational efficiency.' }}</p>
                        </div>
                        <div class="tour__content-cta">
                            <div class="trust">
                                <div class="review">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <p class="fw-7">{{ labelsState['rated_excellent'] || "Rated Excellent" }}</p>
                            </div>
                            <div class="action">
                                <a :href="registerUrl" class="btn btn--primary" target="_blank">
                                {{ labelsState['request_demo'] || "Request a Demo - It's Free" }}
                                </a>                                                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: "Introduction",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);
        const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;

        return {
            labelsState,
            languageCodes,
            changeLanguage,
            registerUrl,
        };
    },
};
</script>
